// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

@charset 'utf-8';
 
@import 'settings';
@import 'foundation';
// @import 'motion-ui';
 
@include foundation-global-styles;
@include foundation-grid;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
@include foundation-visibility-classes;
@include foundation-float-classes;
@include foundation-accordion;
@include foundation-accordion-menu;
//@include foundation-badge;
@include foundation-breadcrumbs;
//@include foundation-button-group;
@include foundation-callout;
@include foundation-close-button;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
// @include foundation-flex-video;
@include foundation-label;
//@include foundation-media-object;
@include foundation-menu;
@include foundation-off-canvas;
//@include foundation-orbit;
//@include foundation-pagination;
//@include foundation-progress-bar;
//@include foundation-slider;
//@include foundation-sticky;
@include foundation-reveal;
//@include foundation-switch;
@include foundation-table;
//@include foundation-tabs;
//@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
 
// @include motion-ui-transitions;
// @include motion-ui-animations;


///////////////////////////
// CUSTOM STYLES BELOW HERE
///////////////////////////

//////////////////////////////////
// GLOBALS NOT SET IN FOUNDATION
//////////////////////////////////

html {
	box-sizing: border-box;
	min-height:100%;
}

*, *:before, *:after {
	box-sizing: inherit;
}

article {
	margin-top:2em;

	p {
		font-size:125%;
	}
}

.flexrow {
	display: flex;
	flex-direction: row;
	align-items: stretch;
	justify-content: space-between;
}

.bookReviewTable {
	margin: 2em 0 2em 0;
}

.book--imageLink {
	display: flex;
	flex-direction:column;
	justify-content: flex-start;
	align-items: center;
	background-color:$light-gray;

	small {
		color:$black;
	}
}

.book--imageLink, .book--ratings, .book--information {
	padding:1em;
}

.book--ratings, .book--information {
	min-width:30%;
	flex: 1 1 auto;
}

.book--information table tr td:first-child, .book--ratings table tr td:first-child {
	font-weight: bold;
}

.book--ratings table {

	border:10px solid $wwdGreen;
	border-collapse:separate;
	border-spacing: 2px;
	margin-bottom:1em;

	th {
		background-color:$wwdDarkGreen;
		color:$white;
		font-family: 'Roboto Slab', sans-serif;
		font-size:110%;
		text-transform:uppercase;
		padding:.5em;
		text-align:left;

		a {
			color:$white;
		}
		a:hover {
			color:$light-gray;
		}
	}

	tr {
		background-color: $wwdGreen;
	}

	tr:nth-child(even) > td {
		background-color:rgba(255,255,255,.2);
	}

	td > img {
		width:75%;
	}
}

//////////////////////////////////
// HEADER STUFF HERE
//////////////////////////////////

header {
	position: relative;
	background-color:$wwdGreen;
}

.header--logoIcon {
	width: 40px;
	height: 40px;
	margin-right:.5em;
}

.header__content--first {
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 3.5em;

	div {
		flex: 1;

		* {
			display: flex;
			flex-direction: row;
			align-items: center;
		}

		form {
			justify-content:flex-end;
			padding-right:1em;
		
			input {
				margin:0;
			}

			button {
				background-color: map-get($foundation-palette, primary);
				color: $white;
				height: 35px;
				width:35px;
				padding-left:10px;
			}
		}
	}

	.title {
		font-family: Roboto Slab;
		font-size: 200%;
	}

	input {
		width: 200px;
	}
}

.header__navbar {
	background-color:$wwdDarkGreen;
	border-bottom: 6px solid $wwdYellow;
}

.top-level-menu .menu  {
	box-shadow: 3px 3px 5px rgba(0,0,0,.25);
}

.menu > li > a {
	color:$white;
	padding:1.1rem;
	-webkit-transition: all 0.2s; -moz-transition: all 0.2s; transition: all 0.2s;
}

.menu > div > a {
	color:$white;
	padding:.75rem;
	-webkit-transition: all 0.2s; -moz-transition: all 0.2s; transition: all 0.2s;
}

.top-level-menu > li:hover > a {
	background:$wwdRust;
	-webkit-transition: all 0.2s; -moz-transition: all 0.2s; transition: all 0.2s;
}

.top-level-menu > li > .menu > li:hover > a, .top-level-menu > li > .menu > li > .menu > li:hover > a, .top-level-menu > li > .menu li a:hover {
	background:rgba(255,255,255,.2);
	-webkit-transition: all 0.2s; -moz-transition: all 0.2s; transition: all 0.2s;
}

.menu > li > a > i {
	margin-left:1em;
	-webkit-transition: all 0.2s; -moz-transition: all 0.2s; transition: all 0.2s;
}

.menu > li:hover > a > i {
	transform: rotate(90deg);
}

// .navChildDropdown {
//     display: flex;
//     flex-direction: row;
//     flex-wrap: wrap;
//     width: 350px;
// }

// .navChildDropdown a {
// 	display: inline-block;
// 	color: #fff;
// }

.main-content-area {
	background-color: $white;
	box-shadow: 0 3px 5px 2px #ddd;
}

article a:hover {
	text-decoration:underline;
	font-weight:bold;
}

.Cataloged-row 								{ background-color:#dce5e9; transition: all 0.1s;}
.Cataloged-row:hover 						{ background-color:$statusBlue; color: $white; }
.Confirmed-row 								{ background-color:#e0e9dc; transition: all 0.1s;}
.Confirmed-row:hover 						{ background-color:$statusGreen; color: $white; }
.Unconfirmed-row 							{ background-color:#f5f3e3; transition: all 0.1s;}
.Unconfirmed-row:hover 						{ background-color:$statusYellow; color: $white; }
.Unknown-row 								{ background-color:#efd2d2; transition: all 0.1s;}
.Unknown-row:hover 							{ background-color:$statusRed; color: $white; }
.Disqualified-row 							{ background-color:#cbcbcb; transition: all 0.1s;}
.Disqualified-row:hover 					{ background-color:$statusGray; color: $white; }
.Posted-row, .Underground-row 				{ background-color:#d8cfc4; transition: all 0.1s;}
.Posted-row:hover, .Underground-row:hover 	{ background-color:$statusBrown; color: $white; }
.Inundated-row 								{ background-color:#e3dce9; transition: all 0.1s;}
.Inundated-row:hover 						{ background-color:$statusPurple; color: $white; }

.Cataloged-row:hover a, .Confirmed-row:hover a, .Unconfirmed-row:hover a, .Unknown-row:hover a, .Disqualified-row:hover a, .Inundated-row:hover a, .Posted-row:hover a	{ color: $white; font-weight: bold; }

.tableWrapper {
	display:table;
	width:100%;
	border-collapse:separate; 
    border-spacing:2px;
    text-align: left;
	font-size:110%;

	.tableRow-Header, .tableRow {
		display: table-row;
		width:100%;
	}

	.tableRow-Header {
	    background-color:$wwdDarkGreen;
		color: #FFF;
		font-weight:bold;

		a > i {
			padding-left: .5em;
			color: white;
		}
	}

	.tableCell {
		display: table-cell;
		padding:.5em;
	}

	.fullWidthCell {
		width:100%;
	}
}

.tableIconColumn {
	width: 3.4em;
}

.table-accessIconCell {
	max-width:3.75em;
}

.letterSort {
	font-size: 85%;
	padding: .25em .5em .25em .5em;
	font-weight: bold;
	border-radius: 3px;
	text-decoration: none;
	-webkit-transition: all 0.1s; -moz-transition: all 0.1s; transition: all 0.1s;
}

.letterSort:hover {
	background-color:$wwdDarkGreen;
	color: $wwdYellow;
	text-decoration:none;
}

.sortColumn {
	background: rgba(255,255,255,.3); 
}

.updateTimestamp {
	border-bottom: 5px solid gray;
}

hr {
	border:1px solid #EEE;
	margin-top:-1em;
}

.modalOpenRight {
	float: right;
	margin-left: 8px;
	margin-top:5px;
}

a.button > i {
	padding-right:1em;
}

a.button:hover {
	text-decoration:none;
}

.mapbuttons {
	display:inline-block;
}

.indent {
	margin-left:2em;
}

.searchframe {
	padding:1em;
}


/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
/*~~~~~~~~~~~~~~~~~~ Sidebar Specific ~~~~~~~~~~~~~~~~~~~*/
/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
aside {
	width:100%;
	height:auto;
	float:right;
	margin-left:3em;
	margin-bottom:3em;
}

.waterfall-info-sidebar table {
	width:100%;
	background-color: $wwdTan;
	border:10px solid $wwdGreen;
	border-collapse:separate;
	border-spacing: 2px;
	margin-bottom:1em;

	th {
		background-color:$wwdDarkGreen;
		color:$white;
		font-family: 'Roboto Slab', sans-serif;
		font-size:110%;
		text-transform:uppercase;
		padding:.5em;
		text-align:left;

		a {
			color:$white;
		}
		a:hover {
			color:$light-gray;
		}
	}

	th+th {
		color:#FAE658;
	}

	tr {
		background-color: $wwdGreen;
	}

	tr:nth-child(even) > td {
		background-color:rgba(255,255,255,.2);
	}

	.tableIcon {
		padding-right:.5em;
	}
}


.waterfall-info-sidebar table td {
	font-size:90%;
	padding:.5em;
}
.waterfall-info-sidebar table td:first-child {
	padding-left:1em;
	width:45%;
	font-weight: bold;
}
.waterfall-info-sidebar table td:second-child {
	padding-right:1em;
	width:55%;
}

.ratings-global, .ratings-country, .ratings-state {
	color: $white;
	font-weight: bold;
	font-size: 110%!important;
}

.ratings-global {
	background-color: $wwdRust!important;
}

.ratings-country {
	background-color: $wwdGold!important;
}

.ratings-state {
	background-color: $wwdYellow!important;
}
// .waterfall-info-sidebar table.Cataloged { border-color: $statusBlue!important; }
// .waterfall-info-sidebar table.Cataloged th{	background-color:$statusBlue; }

// .waterfall-info-sidebar table.Confirmed { border-color: $statusGreen!important; }
// .waterfall-info-sidebar table.Confirmed th {	background-color:$statusGreen; }

// .waterfall-info-sidebar table.Unconfirmed { border-color: $statusYellow!important; }
// .waterfall-info-sidebar table.Unconfirmed th {	background-color:$statusYellow; }

// .waterfall-info-sidebar table.Unknown, .waterfall-info-sidebar table.Rumored { border-color: $statusRed!important; }
// .waterfall-info-sidebar table.Unknown th, .waterfall-info-sidebar table.Rumored th {	background-color:$statusRed; }

// .waterfall-info-sidebar table.Disqualified { border-color: $statusGray!important; }
// .waterfall-info-sidebar table.Disqualified th {	background-color:$statusGray; }

// .waterfall-info-sidebar table.Posted { border-color: $statusBrown!important; }
// .waterfall-info-sidebar table.Posted th {	background-color:$statusBrown; }

// .waterfall-info-sidebar table.Inundated { border-color: $statusPurple!important; }
// .waterfall-info-sidebar table.Inundated th {	background-color:$statusPurple; }

// #statusIcon {
// 	vertical-align:middle;
// 	margin-left: -.5em;
// 	height: 2em;
// }

.tableIcon {
	height: 2em;
}

.fullIcon {
	height: 3em;
	max-width:none;
}

#iconCell {
	margin: .5em 0 1em 0;
	padding: .25em 0 .25em 0;
	text-align: center;

	img {
		display: inline-block;
		margin:auto;
	}
}

.sortColumn {
	background: rgba(255,255,255,.3); 
}

.keytable {
	border:1px solid #000;
	cursor: default!important;
	margin-bottom:4em;
	
	tr {
		margin: 1px;
		
		td {
			padding:1em;
			font-weight: normal;
		}
	}
}


#pictureCarousel, #pictureCarousel2 {
	min-height:190px;
	margin-top: auto;
	text-align: left;
	margin-bottom: auto;

}

#pictureCarousel, #pictureCarousel2 {
	
	i:first-of-type {      /*for Prev button */
		position: absolute;
		left:0;
	}

	i:last-of-type {      /*for Next button */
		position: absolute;
		right:0;	
	}
}

.carouselImg {
	position: relative;
	margin-left: auto;
	margin-right: auto;
	margin-top: 1.5em;
	margin-bottom: 1.5em;
	border:6px solid white;
	box-shadow: 2px 2px 5px 2px #999;
}

#pictureCarousel, #pictureCarousel2 {
	
	.noPics {
		text-align: center;
		font-size: 120%;
		font-weight: bold;
		margin: 1em;
	}
}

#pictureCarousel i, #pictureCarousel2 i {
  position: absolute;
  top: 40%;
  z-index: 999;
  display: block;
  padding: 4% 3%;
  width: auto;
  height: auto;
  color: #2a2a2a;
  text-decoration: none;
  font-weight: 600;
  font-size: 24px;
  opacity: 0.8;
  cursor: pointer;
}

.slick-disabled {
	color: #dfdfdf!important;
	cursor: default!important;
}

#pictureCarousel a, #pictureCarousel2 a, .top100Container div, .close-button {
	outline: 0;
}

.nwscomparetable {
	border-collapse:separate;
	border-spacing:2px;
	text-align:left;
	font-size:90%;
	background-color:$medium-gray;
	margin-bottom:4em;

	th {
		background-color:$dark-gray;
		color:$white;
	}

	td {
		background-color:$light-gray;		
	}

	td.empty {
		background-color:$white;
	}
}

.nwsdatatable {
	border-collapse:separate; 
    border-spacing:1px;
    text-align: left;

    tr { 

    	th {
    	background-color:$wwdDarkGreen;
		color: #FFF;
		}

		a > i {
			padding-left: .5em;
			color: white;			
		}

		td:first-child {
			width: 3.4em;
		}
    }
}


/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
/*~~~~~~~~~~~~~~~~~~ BODY SUB-AREAS ~~~~~~~~~~~~~~~~~~~*/
/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

.nameInfo {
	padding: 5px;
	background-color: #fafafa;
	border:1px solid #ececec;
	border-radius: 2px;
	width: 61%
}
.nameInfo span {
	font-weight: bold;
}

.nameAltList {
	font-size: 90%;
	margin-top: -.75em;
}

#nearbyFallsList > table {
	border-collapse:separate; 
    border-spacing:1px;
    text-align: left;
    padding-bottom: .75em;
}

#nearbyFallsList > table td {
	padding: .25em 2.5em 0 0;
}

h1 {
	color:$statusBlue;
	font-weight:bold;
}

h3.subheader {
	font-weight:bold;
	color:$dark-gray;
	margin-top:-.2em;
	margin-bottom: 1.5em;
}

h4 {
	border-bottom: 2px solid $wwdYellow;
	margin: 1.8em 0 1em 0;
	color:$dark-gray;
}

h4 i {
	padding-right: .75em;
	color:$statusBlue;
}

h4 span { 
	position: relative;
	top:.6em;
    background:$white; 
    padding:0 .625em; 
}

.nameInfo {
	padding: 5px;
	background-color: $light-gray;
	border:1px solid $medium-gray;
	border-radius: 2px;
	width:100%;
}

.nameInfo span {
	font-weight: bold;
}

.nameAltList {
	font-size: 90%;
	margin-top: -.75em;
}

.reveal {
	outline:0;
	box-shadow: 0 3px 5px 2px rgba(0,0,0,.25);
	top:0!important;
}

.closeX {
	float:right;
	margin-top:-1.5em;
	margin-right:-1em;
	font-size:200%;
	color:$medium-gray;
	-webkit-transition: all 0.2s; -moz-transition: all 0.2s; transition: all 0.2s;
}

.callout > i {
	font-size: 250%;
	float:left;
	padding-right: .5em;
}

.primary > i { color: map-get($foundation-palette, primary); }
.alert > i { color: map-get($foundation-palette, alert); }
.warning > i { color: map-get($foundation-palette, warning); }
.success > i { color: map-get($foundation-palette, success); }

.adcell {
	padding-top: 2em;
	padding-bottom:3em;

	p {
		text-align: center;
		font-size: 80%;
	}
}

.column-text {

	margin:2em 0 2em 0;

	li {
		list-style: none;
	}

	span {
		column-span: 5;
	}
	
    -webkit-column-count: 5; /* Chrome, Safari, Opera */
    -moz-column-count: 5; /* Firefox */
    column-count: 5;

    -webkit-column-gap: 2em; /* Chrome, Safari, Opera */
    -moz-column-gap: 2em; /* Firefox */
    column-gap: 2em;

    -webkit-column-rule: 1px solid $wwdGreen; /* Chrome, Safari, Opera */
    -moz-column-rule: 1px solid $wwdGreen; /* Firefox */
    column-rule: 1px solid $wwdGreen;	

}



.callout-statusBox {
	display:flex;
	flex-direction: row;
	align-items: center;
	border-radius:3px;

	img {
		flex-grow:0;
		flex-shrink:0;
		height:70px;
		width:70px;
		margin-right:10px;
	}

	div {
		background-color: $white;
		width:100%;
		min-height:70px;
		padding:.5em 1em .5em 1em;
	}
}

.callout-statusBox.Cataloged {
	background-color:$statusBlue;
	border:10px solid $statusBlue;
}

.callout-statusBox.Confirmed {
	background-color:$statusGreen;
	border:10px solid $statusGreen;
}

.callout-statusBox.Unconfirmed {
	background-color:$statusYellow;
	border:10px solid $statusYellow;
}

.callout-statusBox.Unknown, .callout-statusBox.Rumored {
	background-color:$statusRed;
	border:10px solid $statusRed;
}

.callout-statusBox.Inundated {
	background-color:$statusPurple;
	border:10px solid $statusPurple;
}

.callout-statusBox.Disqualified {
	background-color:$statusGray;
	border:10px solid $statusGray;
}

.callout-statusBox.Posted, .callout-statusBox.Underground {
	background-color:$statusBrown;
	border:10px solid $statusBrown;
}






/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
/*~~~~~~~~~~~~~~~~~~ Footer Specific ~~~~~~~~~~~~~~~~~~~*/
/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

footer {
	position:relative;
	bottom:0;
	width:100%;
	left:0;
	background:radial-gradient(100% 100% at 50% 100%, $wwdGreen, $wwdDarkGreen);
	//(200px 100px ellipse at 25% 50%, yellow, #009966, purple);
	//background:$wwdDarkGreen;
	margin-top:2em;
	color:$white;

	li {
		list-style: none;
		margin-left:-1em;
	}

	a {
		color:$wwdTan;
	}

	a:hover {
		font-weight:bold;
		color:$white;
	}
}

.footer--logoIcon {
	margin-top:-90px;
	width:120px;
	height:120px;
	border-radius: 60px;
	background-color: $white;
	border:5px solid $white;
	//align-self:center;
}

.footer__content--col {
	display: flex;
	flex-direction:column;
	align-items: center;
	text-align: center;
	margin-left: auto;
  	margin-right: auto;
	justify-content: space-around;
	padding: 2em 2em 0 2em;
	background:rgba($black,.25);

	h2 {
		color:$wwdYellow;
		margin-top:1.25em;
	}
}

.footer__content--row {
	display: flex;
	flex-direction:row;
	align-content: left;
	margin-left: auto;
  	margin-right: auto;
	justify-content: space-around;
	padding: 2em;
	background:rgba($black,.25);

	i {
		padding-top:.5em;
		padding-right:1em;
		font-size:250%;
	}
}



/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
/*~~~~~~~~~~~~~~~~~~ Map Box Classes ~~~~~~~~~~~~~~~~~~*/
/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

.mapContainer {
	display: flex;
	flex-direction: row;
	width: 100%;
	height:650px;

	.mapContainer__sidebar {
		display: flex;
		flex-direction: column;

	}

	.mapContainer__main {
		display: flex;
		flex-direction: column;
		align-items:stretch;
		width: 100%;
		padding-left:1em;

		select {
			font-size: 200%;
			font-weight:bold;
		}

	}
}

#errorBar {
	display: none;
	align-items:stretch;
	width: 100%;
	margin:1em 0 1em 0;

	p {
		font-size:120%;
		margin:1em .5em 1em 1em;
	}
}

.warning {
	border: 1px solid map-get($foundation-palette, warning);

	i {
		display:flex;
		align-items: center;
		padding: 1rem;
		background-color: map-get($foundation-palette, warning);
		color: $white;
	}
}

.alert {
	border: 1px solid map-get($foundation-palette, alert);

	i {
		display:flex;
		align-items: center;
		padding: 1rem;
		background-color: map-get($foundation-palette, alert);
		color: $white;
	}
}

.success {
	border: 1px solid map-get($foundation-palette, success);

	i {
		display:flex;
		align-items: center;
		padding: 1rem;
		background-color: map-get($foundation-palette, success);
		color: $white;
	}
}

.primary {
	border: 1px solid map-get($foundation-palette, primary);

	i {
		display:flex;
		align-items: center;
		padding: 1rem;
		background-color: map-get($foundation-palette, primary);
		color: $white;
	}
}


#resultsBox, #messageBox, .mapContainer__sidebar {
	max-width: 20em;
}

#resultsBox {
	height:95%;
	display:none;
	position:relative;
	overflow-y:auto;
	overflow-x:hidden;
	}

#stateCountryWaterfallCount, #linkList, #resultsBox li {
	list-style:none;
}

#resultsBox li {
	padding:.6em .125em .6em .25em;
}

#resultsBox li a:hover {
	color:#C60;
}

#resultsBox li:nth-child(even) {
    background-color: #f8f8f8;
}

#mapListButton, #mapGEbutton {
	display:none;
}

#messagebox {
	display: block;
	padding:1em;
	height:95%;
	background-color: $light-gray;
	border:1px solid $medium-gray;
}

#panLink i {
	font-size:85%;
	padding-right:1em;
}

.map-dropdown {
	background-color: $light-gray;
	border: 1px solid $wwdYellow;
	outline: 0;
	color:$black;
}







.mapbox, .mapboxlg {
	max-height:650px;
	width: auto;
	// background-color: #efefef;
	display:flex;
}

.mapbox {
	margin-top: .5em;
	height: 50vh;
}

.mapboxlg {
	height: 650px;
}

.mapOptions {
	display: flex;
	justify-content:flex-start;
	background-color: $wwdDarkGreen;


	.button {
		background: $wwdDarkGreen;
		margin:0!important;
		padding:1.2em;
	}

	.button:hover {
		background: rgba(255,255,255,.2);
	}	
}

.mapOptions i {
	padding-right: 1em;
}


.displayControls {
	padding-top:0;
}

.displayControls label:hover {
	cursor: pointer;
}

.displayControls label img {
	margin: 0 .5em 0 .5em;
}

#regular-checkbox {
	-webkit-appearance: none;
	background-color: #fafafa;
	border: 1px solid #cacece;
	box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05);
	padding: 9px;
	border-radius: 3px;
	display: inline-block;
	position: relative;
	top:1.5em;
}

#regular-checkbox:active, #regular-checkbox:checked:active {
	box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px 1px 3px rgba(0,0,0,0.1);
}

#regular-checkbox:checked {
	background-color: #e9ecee;
	border: 1px solid #adb8c0;
	box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05), inset 15px 10px -12px rgba(255,255,255,0.1);
	color: #99a1a7;
}

#regular-checkbox:checked:after {
	content: '\2714';
	font-size: 14px;
	position: absolute;
	top: 0px;
	left: 3px;
	color: #99a1a7;
}

.displayControls label img {
	height: 30px;
}

.mapOptions li:hover .zoomToMenu, .mapOptions li:hover .displayControls {
	opacity: 1;
    border-top: .35em solid $wwdYellow;
    color: #000;
    visibility: visible;
    overflow: visible;
    box-shadow: 3px 3px 5px rgba(0,0,0,.25);
}

.ddMenuColumn {
	flex:1;
	padding-right:1em;
}

#zoomToMenu--Button {
	display:inline-flex;
}


.ddMenuColumn > h3 {

    font-size: 110%;

}

.ddMenuColumn ul {
	list-style-type: none;
	padding: 0;
}

.ddMenuColumn ul li {
	margin-left:-1em;
}


#loaderIcon {
	position: fixed; /* or absolute */
	top: 50%;
	left: 50%;
    z-index: 10000;
    color: $white;
    text-shadow: 0px 0px 6px #000000;
    visibility: hidden;
}



/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
/*~~~~~~~~~~~~~~~~~~ Pagination ~~~~~~~~~~~~~~~~~~*/
/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

.pagination {
	list-style: none;
	text-align: center;
	padding-top:2em;
	padding-left:0;
}

.pagination li {
	display: inline-block;
	font-size: 85%;
}

.pagination li a {
	background-color: #efefef;
}

.pagination li a, ul.pagination li span {
	padding: .5em;
	margin: .5em;
	color: $wwdDarkGreen;
	border-radius: 3px;
	text-decoration: none;
	-webkit-transition: all 0.1s; -moz-transition: all 0.1s; transition: all 0.1s;
}

.pagination li a:hover {
	background-color: $wwdDarkGreen;
	color: $wwdYellow;
}


/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
/*~~~~~~~~~~~~~~~~~~ Off-canvas ~~~~~~~~~~~~~~~~~~~*/
/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

.off-canvas > ul li {
	border-bottom: 1px solid rgba(255,255,255,.2);
}

.off-canvas > ul li > a:hover {
	background:$wwdDarkGreen;
}

.mobile-nav {
	width:100%;
	display:flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items:center;
	justify-content:center;
}

.mobileNav-Button {
	flex-grow:1;
	display:inline-block;
	font-size:150%;
	padding: .5em;

	a > i {
		color:$white;
	}
}

.mobileNav-Searchfield {
	flex-grow:5;
	display:inline-block;
	width:80%;
	padding: 0 .25em 0 .25em;

	input {
		margin:0;
	}
}


/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
/*~~~~~~~~~~~~~~~~~~ Home Page Stuff ~~~~~~~~~~~~~~~~~*/
/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

.hpContentFullWidth {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
}

.hpThirdWidthCell {
	display:flex;
	align-items:stretch;

	div {
		margin:auto;
		height:22em;
		width:100%;
	}

	a .primary .logoText {
		font-weight:bold;
		color: $statusBlue;
		text-shadow: none;
	}

	a .primary {
		-webkit-transition: -webkit-transform 0.35s, color 0.35s;
		transition: all 0.35s, color 0.35s;
	}

	a .primary:hover {
		background: rgba($statusBlue,.2);
	}
}

.hpAboutCell {
	display: flex;
	flex-direction:column;
	justify-content:center;
	align-items:center;

	// background: -webkit-linear-gradient(top, rgba(30,30,30,.6) 5%, rgba(20,20,20,0) 75%);
	// background: linear-gradient(to bottom, rgba(30,30,30,.6) 5%, rgba(20,20,20,0) 75%);	


	.hpHeaderLogo {
		display: inline-flex;
		flex-direction: row;
		justify-content:center;
		align-items:center;
		width: 100%;
		position: relative;
		padding:5em 0 5em 0;	
	}

	h1 {
		position: absolute;
		text-align:center;
		font-size:500%;
		width:100%;
		color: $white;
		margin: auto;
		padding:1em 0 1em 0;
		text-shadow: 4px 4px 15px $black;
	}

	p {
		color: white;
		width: 100%;
		margin: 3em 0 1 em 0;
		background: rgba($black,.35);
		text-align: center;
		text-shadow: 4px 4px 15px $black;
	}
}

.arrowbar, .arrowbar-fill {
	display:flex;
	flex-direction: row;
	justify-content: center;
}

.arrowbar-fill {
	background: $wwdGreen;
}

.arrowbar {
	background: transparent;
}

.arrow-down {
	width: 0; 
	height: 0; 
	border-left: 50px solid transparent;
	border-right: 50px solid transparent;

	border-top: 30px solid $white;
}	



.stateButtonBar {
	background: -webkit-linear-gradient(top, rgba(68,68,68,0) 0%, rgba(20,20,20,0.6) 85%);
	background: linear-gradient(to bottom, rgba(68,68,68,0) 0%, rgba(20,20,20,0.6) 85%);
	color: white;
	height:100%;
	padding: 2em;
	text-align: center;

	h2 {
		text-shadow: 4px 4px 15px $black;
		padding-bottom:1em;
	}

	div {
		display:flex;
		align-items: center;
		align-content:center;
		flex-direction:column;
		justify-content: space-around;

		a {
			font-size: 150%;
			font-weight: bold;
			background-color:$wwdGold;
			box-shadow: 3px 3px 5px rgba(0,0,0,.25);			

		}

		a:hover {
			background-color:$statusBlue;
		}
	}
}

.buttonExtraRightMargin {
	margin-right:1.5em;
}

.hpSearchCell>h2, .stateButtonBar>h2 {
	font-size:300%;
	padding-top:.3em;
	padding-bottom:0;
}

.hpSearchCell {
	display: flex;
	justify-content:space-around;
	align-items:center;
	background-color: $wwdGreen;
	color: $medium-gray;
	width: 100%;

	h2 {
		color:$wwdDarkGreen;
	}

	input {
		display: inline-block;
		margin: 1em 0 2em 0;
		padding:1em;
		font-size: 160%;
		line-height: 160%;
		box-sizing: border-box;
	}

	button {
		margin-top:-1.75em;

		i {
			font-size: 250%;
			color: $wwdDarkGreen;
			margin-left: -1em;
			transition: all .2s ease-in-out;
		}
	}

	button:hover i {
		transform: scale(1.2);
	}

}

.hpContentFullWidth form {
	width:100%;
}

.hpBackgroundImg--props {
	background-position: center;
	background-repeat: no-repeat;
	background-size:cover;
	background-origin: border-box;

	box-shadow: inset 0px 4px 10px $dark-gray;
}

.hpBackgroundImg {
	min-height:600px;
	max-height:950px;
	background-color:$wwdGreen;
}

.hpBackgroundMap {
	min-height:300px;
	max-height:400px;
	background-color:$wwdTan;
}

.hpListsBar {
	background-color: $wwdDarkGreen;
	border-bottom: 5px solid $wwdYellow;
	text-align: center;

	.row {
		display: flex;
		flex-direction: column;
		justify-content: space-around;

		div {
			display:flex;
			align-items: center;
			align-content:center;
			flex-direction:row;
			justify-content: space-around;

			a {
				font-size: 120%;
				font-weight: bold;
				background-color:$wwdGold;
			}

			a:hover {
				background-color:$statusBlue;
			}
		}
	}

	h2 {
		color:$white;
		text-shadow: 4px 4px 15px $black;
		padding:1em 0 .5em 0;
	}
}

.whiteBottomBorder {
	border-bottom:5px solid $white;
}

/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
/*~~~~~~~~~~~~~~~~~~ Google Maps Infowindows ~~~~~~~~~~~~~~~~~~~*/
/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
#mapbox > div > div > div:nth-child(1) > div:nth-child(4) > div:nth-child(4) > div > div:nth-child(1) > div:nth-child(4),
#mapbox > div > div > div:nth-child(1) > div:nth-child(4) > div:nth-child(4) > div > div:nth-child(1) > div:nth-child(3) > div:nth-child(1) > div,
#mapbox > div > div > div:nth-child(1) > div:nth-child(4) > div:nth-child(4) > div > div:nth-child(1) > div:nth-child(3) > div:nth-child(2) > div {
	background: $wwdDarkGreen!important;
}

#mapbox > div > div > div:nth-child(1) > div:nth-child(4) > div:nth-child(4) > div > div:nth-child(3)::before {
	font-family: FontAwesome;
	content: '\f00d';
	color: $white;
	padding-left:5px;
}

#mapbox > div > div > div:nth-child(1) > div:nth-child(4) > div:nth-child(4) > div > div:nth-child(3) > img {
	display: none;
}

.gm-style-iw {
	top: 11px !important;
	background: $wwdDarkGreen;
	color:$white;
	font-size: 15px!important;

	a {
		color:$white;
		text-decoration: none;
	}

	a:hover {
		font-weight: normal!important;
	}
}

/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
/*~~~~~~~~~~~~~~~~~~ Media Queries ~~~~~~~~~~~~~~~~~~~*/
/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

@media only print {
   body { min-width:$global-width; }

	div.sidebar-for-print {
		width:300px;
		height:auto;
		float:right;
		margin-left:3em;
		margin-bottom:3em;
		background: $white;
	}

	div.large-8 {
		width:1100px;
	}
	
	h4 {
		border-bottom: none;
	}

	.nameInfo {
		border:none;
	}

	img { 
    	max-width: none !important; 
	}

	.print-sidebar-image {
		padding-top:10px;
		padding-bottom:10px;
	}
}


@media only screen and (max-device-width : 767px) {

	.main-content-area {
		padding: 1em .5em 1em .5em;
	}

	.logoText {
		text-align:center;
		font-size: 150%;
	}

	.footerContainer {
		font-size:80%;
	}

	.hpAboutCell > h1 {
	font-size: 250%;
	}

	.hpAboutCell > p {
		font-size: 90%;
		padding:.5em 2em .5em 2em;
	}

	.hpSearchCell input {
		width: 65%;
	}

	a .primary .logoText {
		font-size:275%;
	}
}


@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {

	.main-content-area {
		padding:1em;
	}

	.hpAboutCell > h1 {
	font-size: 350%;
	}

	.hpAboutCell > p {
		font-size: 110%;
		padding:2em 5em 2em 5em;
	}

	.hpSearchCell input {
		width: 75%;
	}	

	a .primary .logoText {
		font-size:325%;
	}
}



@media only screen and (min-device-width : 1025px) {

	.main-content-area {
		padding:2em;
	}

	.hpAboutCell > h1 {
		font-size: 400%;
	}

	.hpAboutCell > p {
		font-size: 150%;
		padding:2em 5em 2em 5em;
	}

	.hpSearchCell input {
		width: 85%;
	}

	a .primary .logoText {
		font-size:325%;
	}
}

@media only screen and (min-width : 1200px) {

	a .primary .logoText {
		font-size:400%;
	}
}	

